<template>
  <div v-if="accessToken != 'pc'" style="padding: 30px;">
    <p>
      <!-- <input class="formInput" data-test-key="input-access-token" v-model="accessToken" v-on:keyup="setToken" placeholder="Access token" /> -->
    </p>
  </div>
  <div class="content" v-else>
    <Navigation />
    <router-view v-cloak/> 
    <Footer />
  </div>

  <!-- <div class="content">
    <Navigation />
    <router-view v-cloak/> 
    <Footer />
  </div> -->

</template>

<script lang="ts">

import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import { companyUserAPI } from "@/api/companyUser";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import store from "./store/vuex";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  name: "App",
  components : {
    Navigation, Footer
  },
  data() {
    return {
      // accessToken : localStorage.getItem('jbpw-auth-token') !== null ? 'pc' : '',
      accessToken : localStorage.getItem('dev-token'),
      hasAccess : false
    };
  },
  async created() {
    if(new URL(location.href).searchParams.get('dev-token') == 'pc')
    {
      localStorage.setItem('dev-token', 'pc');
    }


    // var dev = localStorage.getItem('dev-token');
    // if (dev == 'pc'){
    //   this.hasAccess = true;
    // }

    // if(localStorage.getItem('jbpw-auth-token') != null)
    // {
    //   const result = await log.catchPromise(() =>
    //   pageStatus.observe(async () => {
    //       const result = await companyUserAPI.get();
    //       store.dispatch('companyUserStore', result);
    //     })
    //   );
    //   if (!result.success) {
    //       store.dispatch('companyUserStore', null);
    //       localStorage.removeItem('jbpw-auth-token');
    //   }  
    // }
  },
  // methods: {
  //   async setToken() {
  //     localStorage.setItem('dev-token', this.accessToken)
  //   }
  // }
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 20px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.active {
    background-color: #59c9e8;
  }

.activeText {
    font-weight: bold;
    color: #59c9e8;
  }
</style>
