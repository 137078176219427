<template>
  <div style="width: 350px; margin: auto; height: 35px;">
    <button v-on:click="isFormOpened = !isFormOpened">Create Email Alert</button>
      <div v-if="pageLoading">
        <img class="_imgLoader" src="@/assets/images/loading.gif"/>
      </div>
      <div v-else class="" >
        <div v-if="!isEmailSubscriptionFormSubmitted && isFormOpened" style="
          position: fixed;
          z-index: 9998;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: table;
          transition: opacity 0.3s ease;" >
          
          <div style="margin: 10%; background-color: #fff;">
            <div style="padding: 50px; max-width: 500px; margin: auto;">
              <button style="float: right;" v-on:click="isFormOpened = false">Close</button>

              <p><b>Get emailed new jobs matching your search</b></p>

              <!-- Search: Remote selection -->
              Remote Only <input type="checkbox" id="checkbox" v-model="this.search.isRemote">
              <!-- Search: Salary selection -->
              <select style="margin-left:20px; min-width: 180px;" class="formInputSmall" v-model="this.search.minSalary">
                <option disabled value=null>Min Salary</option>
                <option v-bind:key="salaryBracket" v-for="salaryBracket in salaryBrackets" :value="salaryBracket">{{ salaryBracket.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0}) }}+</option>
              </select>
              <br/>
              <br/>
              <!-- Search: Location selection -->
              <select style="margin-left:20px; min-width: 180px;" class="formInputSmall" v-model="this.search.location">
                <option disabled value=null>Location</option>
              </select>
              <!-- Search: ContractType selection -->
              <select style="margin-left:20px; min-width: 180px;" class="formInputSmall" v-model="this.search.contractType">
                <option disabled value=null>Contract Types</option>
                <option v-bind:key="contractType" v-for="contractType in contractTypes" :value="contractType">{{ contractType }}</option>
              </select>
              <br/>
              <br/>
              <multiselect 
                v-model="this.search.tags" 
                :options="options" 
                :searchable="false" 
                :multiple="true" 
                :close-on-select="false" 
                :clear-on-select="true"              
                :preserve-search="false" 
                placeholder="Pick some tags" 
                :select-label="'Tap to select'"
                :deselect-label="'Tap to remove'"
                label="" 
                track-by="" 
                :preselect-first="false">
                <template #selection="{ values, isOpen }">
                  <span class="multiselect__single"
                        v-if="values.length"
                        v-show="!isOpen">
                        {{ values.length }} tags selected: 
                        <li style="display:inline-block;" v-bind:key="tag" v-for="tag in this.search.tags"  >
                          <span style="border: 1px solid black; padding: 5px; font-size: 9pt; margin-right: 3px;">
                            {{ tag }}
                          </span>
                        </li>
                  </span>
                </template>
              </multiselect>
              <br/>
              <br/>
              <!-- Form -->
              <div>
                <input data-test-key="input-email-subscription-email" style="width: 240px;" class="formInput" v-model="state.emailSubscriptionForm.emailAddress" placeholder="enter email.." />
                <div>
                  <ValidationMessage v-if="v$.emailSubscriptionForm.emailAddress.$error" :validationMessage ="v$.emailSubscriptionForm.emailAddress.$errors[0].$message"/>
                </div>
                <select data-test-key="input-ddl-email-subscription-frequency" style="width: 120px;" class="formInput" v-model="state.emailSubscriptionForm.frequencyType">
                  <option v-bind:key="frequencyType" v-for="frequencyType in emailSubscription.frequencyTypes" :value="frequencyType">{{frequencyType}}</option>
                </select>
                <button data-test-key="button-email-subscription-submit" style="width: 120px;" class="btn" v-on:click="handleEmailSubscription">Subscribe</button>
            </div>
            <FeedbackNotification v-if="this.notification.text != ''" 
              :feedbackText="this.notification.text"
              :feedbackLevel="this.notification.level" />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import { jobListingCategoryAPI } from "@/api/jobListingCategory";
import { emailSubscriptionAPI } from "@/api/emailSubscription";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import { reactive, computed } from 'vue' 
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessage from "@/components/ValidationMessage.vue";
import FeedbackNotification from "@/components/FeedbackNotification.vue";
import Multiselect from 'vue-multiselect'

const pageStatus = useStatus();
const log = useErrorLogger();

export default{
  name: "EmailSubscriptionForm",
  components : {
    ValidationMessage, FeedbackNotification, Multiselect
  }, 
  props: ["handleResetForm", "isRemote", "contractType", "tags", "location", "minSalary"],
  setup () {
    const state = reactive({
      emailSubscriptionForm: {
        emailAddress : '',
        frequencyType : 'Daily'
        }
      });
      const rules = computed(() => {
        return {
          emailSubscriptionForm: {
            emailAddress: { required, email },
          }
        }
      })
      const v$ = useVuelidate(rules, state)
      return { state, v$ }
  },
  data() {
    return {
      pageLoading : false, 
      notification : {
        text : "",
        level : "",
      },
      resetForm : this.handleResetForm,
      isFormOpened : false,
      isEmailSubscriptionFormSubmitted : false,
      emailSubscription :{
        frequencyTypes : ['Daily', 'Weekly', 'Monthly']
      },
      search: { 
        searchTerm: this.searchTerm,
        isRemote: this.isRemote == null ? null : this.isRemote,
        tags: this.tags,
        minSalary: this.minSalary,
        location: this.location,
        contractType: this.contractType
      },
      salaryBrackets : [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000],
      contractTypes : ["FullTime", "PartTime", "Contract", "Internship"],
      jobListingCategories : [],
      value: this.tags,
      options: []
    };
  },
  watch: {
    handleResetForm(e) {
        //alert(this.handleResetForm)
        this.isEmailSubscriptionFormSubmitted = false;
        this.notification.text = '';
        this.notification.level = '';
    }
  },
  mounted() {
      this.getJobListingCategories();
  },
  methods: {
    async handleEmailSubscription() {
      // validate the form
      this.v$.$validate();
      if(!this.v$.$error)
      {
        this.pageLoading = true;
        const result = await log.catchPromise(() =>
          pageStatus.observe(async () => {
            const result = await emailSubscriptionAPI.create(
              this.state.emailSubscriptionForm.emailAddress, 
              this.tags, 
              null, 
              this.minSalary,
              this.location,
              this.isRemote,
              this.state.emailSubscriptionForm.frequencyType,
              this.contractType,
              '');
            if (result.data)
            {
              this.notification = { text : "Subscription Added", level : "success" };
              //this.isEmailSubscriptionFormSubmitted = true;
              //this.resetForm = false;
            }
          })
        );
        this.pageLoading = false;
        if (!result.success) {
          this.notification = { text : result.data, level : "error" };        
        }    
      }
    },
    async getJobListingCategories() {
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await jobListingCategoryAPI.getCategories();
          result.forEach((element) => { 
            this.options.push(element.Name);
            //this.options.push({name: element.Name, jobCount: element.JobListingCount});
          })
          // this.tags.forEach((element) => { 
          //   this.value.push({name: element, jobCount: element.JobListingCount});
          // })
        })
      );
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
