<template>
  <div data-test-key="manage-email-subscription-page">
    <h1>Manage Email Subscription</h1>
    <FeedbackNotification v-if="this.notification.text != ''" 
      :feedbackText="this.notification.text" 
      :feedbackLevel="this.notification.level"/>  
    <div v-if="pageLoading">
      <img class="_imgLoader" src="@/assets/images/loading.gif"/>
    </div>
    <div v-else>      
      <p><i>We'll package all searches into one email with the most relevent jobs to you..</i></p>
      <table style="margin-left: auto; margin-right: auto;">
          <tr v-bind:key="emailSubscriptionByFrequency" v-for="emailSubscriptionByFrequency in emailSubscriptionsResults">
            <h2>Sent {{ emailSubscriptionByFrequency.Frequency }}</h2>
              <tr>
                <td style="min-width:120px; font-weight: bold;">Tag</td>
                <td style="min-width:120px; font-weight: bold;">Min Salary</td>
                <td style="min-width:120px; font-weight: bold;">Location</td>
                <td style="min-width:120px; font-weight: bold;">Remote Only</td>
                <td style="min-width:120px; font-weight: bold;">Contract Type</td>
                <td style="min-width:120px; font-weight: bold;">IsActive</td>
                <td></td>
              </tr>
              <tr v-bind:key="emailSubscription.Identifier" v-for="emailSubscription in emailSubscriptionByFrequency.EmailSubscriptions">
                <td v-if="emailSubscription.EmailSearchSubscription.CategoryName">{{ emailSubscription.EmailSearchSubscription.CategoryName }}</td>
                <td v-else>---</td>
                <td v-if="emailSubscription.EmailSearchSubscription.MinSalary">{{ emailSubscription.EmailSearchSubscription.MinSalary.toLocaleString('en-US', { style: 'currency',currency: 'USD', maximumFractionDigits: 0}) }}</td>
                <td v-else>---</td>
                <td v-if="emailSubscription.EmailSearchSubscription.Location">{{ emailSubscription.EmailSearchSubscription.Location }}</td>
                <td v-else>---</td>             
                <td v-if="emailSubscription.EmailSearchSubscription.IsRemote">{{ emailSubscription.EmailSearchSubscription.IsRemote }}</td>
                <td v-else>---</td>
                <td v-if="emailSubscription.EmailSearchSubscription.ContractType">{{ emailSubscription.EmailSearchSubscription.ContractType }}</td>
                <td v-else>---</td>
                <td>
                  <input v-on:click="handleSetEmailSubscriptionActiveStatusClick(emailSubscription.Identifier)" v-model="emailSubscription.IsActive" type="checkbox" id="checkbox">
                </td>
                <td>
                  <button v-on:click="handleRemoveEmailSubscriptionClick(emailSubscription.Identifier)">remove</button>
                  </td>
                </tr>
              </tr>
        </table>
      </div>
  </div>
</template>

<script>
import { emailSubscriptionAPI } from "@/api/emailSubscription";
import { useStatus } from "@/api/composables/status";
import { useErrorLogger } from "@/api/composables/errorLogging";
import FeedbackNotification from "@/components/FeedbackNotification.vue";

const pageStatus = useStatus();
const log = useErrorLogger();

export default {
  components: { FeedbackNotification },
  data() {
    return {
      emailSubscriptionsResults : [],
      pageLoading : true,      
      notification : {
        text : "",
        level : "",
      },
    };
  },
 created() {
    this.getEmailSubscriptionsResult()
 },
  mounted() {
    document.title = 'Email Subscription - TheJobTap.com';
  },
  methods: {
    async getEmailSubscriptionsResult() {
      this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await emailSubscriptionAPI.get(this.$route.params.groupidentifier);
          this.emailSubscriptionsResults = result;
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }    
    },
    async handleRemoveEmailSubscriptionClick(identifier) {
      this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await emailSubscriptionAPI.remove(identifier);
          this.getEmailSubscriptionsResult()        
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };           
      }    
    },
    async handleSetEmailSubscriptionActiveStatusClick(identifier) {
      this.pageLoading = true;
      const result = await log.catchPromise(() =>
        pageStatus.observe(async () => {
          const result = await emailSubscriptionAPI.setActiveStatus(identifier);
          this.getEmailSubscriptionsResult()
        })
      );
      this.pageLoading = false;
      if (!result.success) {
        this.notification = { text : result.data, level : "error" };        
      }    
    },
  },
};
</script>